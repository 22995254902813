<template>
  <div>
  </div>
</template>
<script>
export default {
  props: {
    idle: Boolean,
  },
  data: () => ({
    isInit: false,
    authenticated: false,
  }),
  mounted() {
    this.logout();
  },
  methods: {
    async logout() {
      let res = "Yes";
      if (this.$store.state.idleVue && !this.$store.state.idleVue.isIdle) {
        const savedInteractions = localStorage.getItem("savedPatientInteractionForms");
        if (savedInteractions) {
          res = await this.$dialog.error({
            text: 'You have unsaved Patient Interactions. Are you sure you want to log out and lose your unsaved Patient Interactions?',
            title: 'Unsaved Patient Interactions',
            actions: ['Yes', 'No'],
          });
        }
      }

      if (res === "Yes") {
        {
          this.$store.commit('setachillesUser', {})
          this.$store.commit('setFootConditions', []);
          this.$store.commit('setFootLocations', []);
          this.$store.commit('setFoots', [])
          this.$store.commit('setForms', [])
          this.$store.commit('setGenders', [])
          this.$store.commit('setGUserProfile', {})
          this.$store.commit('setInteractionTypeForms', [])
          this.$store.commit('setInteractionTypes', [])
          this.$store.commit('setPatientInteractionForms', [])
          this.$store.commit('setProviderTypes', [])
          this.$store.commit('setSignedIn', this.authenticated);
          this.$store.commit("setRestrictedAccess", false);
          this.$store.commit('setStates', []);
          this.$store.commit('setTimeoutAlert', this.authenticated);
          this.$store.commit('setTimeZones', [])
          this.$store.commit('setUsers', [])
          this.$store.commit('setWorklistItemTypes', [])
          this.$store.commit('setWorklistTypes', [])
          localStorage.setItem('getSession', this.authenticated);
          sessionStorage.clear();
          this.$router.push('/')
        }

        if (this.$store.state.idleVue && !this.$store.state.idleVue.isIdle) {
          localStorage.removeItem("savedPatientInteractionForms");
        }
      }
    }

  }
}
</script>
  